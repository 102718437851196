import React, { ReactNode, useMemo } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useSegmentWriteKey, useSegmentCDNUrl } from "./useConfig";
import { StripeSubscriptionStatus } from "@/model/account";

export type AnalyticsTraits = {
  account_id: string;
  created_at: Date;
  email: string;
  first_name: string;
  last_name: string;
  name?: string | undefined;
  is_admin: boolean;
  // For Intercom
  company?:
    | {
        id: string;
        name: string;
        created_at: Date;
        plan: string | null;
        stripe_subscription_status: StripeSubscriptionStatus | null;
        stripe_subscription_period_end: Date | null;
        stripe_customer_id: string | null;
        stripe_subscription_id: string | null;
        active_listings: number;
        hyperdrive_listings: number;
        ai_listings: number;
        repricing_listings: number;
        strategies: number;
        ai_strategies: number;
        marketplaces: number;
        users: number;
        workflows: number;
        il_integration_enabled: boolean;
        scanpower_integration_enabled: boolean;
        restockpro_integration_enabled: boolean;
        last_30_days_sales: number;
      }
    | undefined;
  app_version_major?: number;
  app_version_minor?: number;
  app_version_patch?: number;
};

const AnalyticsContext = React.createContext<
  | {
      analytics: AnalyticsBrowser;
      writeKey: string;
      setWriteKey: (key: string) => void;
      cdnURL: string;
      setCDNUrl: (url: string) => void;
    }
  | undefined
>(undefined);

export function AnalyticsProvider({ children }: { children: ReactNode }) {
  const [writeKey, setWriteKey] = useSegmentWriteKey();
  const [cdnURL, setCDNUrl] = useSegmentCDNUrl();

  const analytics = useMemo(() => {
    return AnalyticsBrowser.load(
      { writeKey: writeKey ?? "", cdnURL },
      { obfuscate: true }
    );
  }, [writeKey, cdnURL]);

  return (
    <AnalyticsContext.Provider
      value={{
        analytics,
        writeKey: writeKey ?? "",
        setWriteKey,
        cdnURL,
        setCDNUrl,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
}

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext);
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};
