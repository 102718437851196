"use client";

import {
  CognitoHostedUIIdentityProvider,
  CognitoUser,
} from "@aws-amplify/auth";
import { Amplify, Auth, Hub } from "aws-amplify";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { EventProperties, SegmentEvent } from "@segment/analytics-next";
import { useMutation } from "@tanstack/react-query";
import { useInterval } from "utils";
import { AnalyticsTraits, useAnalytics } from "./analytics";
import amplifyConfig from "@/config/amplify";
import { Account } from "@/model/account";
import { Settings } from "@/model/settings";
import { AuthUser, DBUser } from "@/model/user";
import callBackend from "@/shared/backend";
import { StartOAuthFlow } from "@/shared/oauth";
import { NewRandomPassword } from "@/shared/password";
import { PlanUsage } from "@/model/billing";
import priceDefinitions from "@/data/prices.json";
import productDefinitions from "@/data/products.json";
import ConfirmationModal from "@/components/modal/confirmation";
import { CannyUserData } from "@/pages/api/canny";
import { Onboarding, OnboardingCondition } from "@/model/onboarding";

// Note: must be a global var so Amplify is configured once
let amplifyConfigured = false;

type FeatureGateModal = {
  title?: string;
  body: ReactNode;
  href?: string;
};

const productDefinitionMap = new Map(
  productDefinitions.map((product) => [product.id, product])
);
const planDefinitionMap = new Map(
  priceDefinitions.map((plan) => [
    plan.id,
    { ...plan, product: productDefinitionMap.get(plan.product_id) },
  ])
);

type MapValueType<A> = A extends Map<any, infer V> ? V : never;

export type PlanDefinitionMapValue = MapValueType<typeof planDefinitionMap>;

const AuthContext = createContext<{
  authUser: AuthUser | null;
  dbUser: DBUser | null;
  setDbUser: (dbUser: DBUser) => void;
  account: Account | null;
  planUsage: PlanUsage | null;
  setPlanUsage: (planUsage: PlanUsage) => void;
  retrievePlanUsage: () => void;
  onboarding: Onboarding | null;
  setOnboarding: (onboarding: Onboarding) => void;
  retrieveOnboarding: () => void;
  currentPlan: PlanDefinitionMapValue | null;
  nextPlan: PlanDefinitionMapValue | null;
  signIn: (
    email: string,
    password: string,
    fromLwa: boolean
  ) => Promise<boolean> | void;
  completeNewPassword: (password: string) => void;
  loginWithAmazon: (signin?: boolean) => void;
  signUp: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => void;
  resendSignUp: (email: string) => void;
  confirmSignUpAndSignIn: (
    email: string,
    password: string,
    code: string
  ) => void;
  logout: () => void;
  forgotPassword: (email: string) => void;
  forgotPasswordSubmitAndSignIn: (
    email: string,
    code: string,
    newPassword: string
  ) => void;
  loginWithAmazonError: string | null;
  updateAttribute: (name: string, value: string) => void;
  support?: boolean;
  updateUserAndAccount: ({}: {
    account_name?: string;
    account_default_currency_code?: string;
    account_logo_url?: string;
    user_first_name?: string;
    user_last_name?: string;
    user_settings?: Settings;
  }) => Promise<string | undefined> | void;
  accountError: string | null;
  invitationCode: string | null;
  makeUserAccountOwner: (id: string) => Promise<string | undefined> | void;
  removeUserFromAccount: (id: string) => Promise<string | undefined> | void;
  configured: boolean;
  disableLoginWithAmazon: () => Promise<string | undefined> | void;
  setCurrentUser: (bypassCache: boolean) => Promise<void> | void;
  changePassword: (
    oldPassword: string,
    newPassword: string
  ) => Promise<string | undefined> | void;
  updateEmail: (newEmail: string) => Promise<string | undefined> | void;
  uploadAccountLogo: (file: File) => Promise<string | undefined> | void;
  removeAccountLogo: () => Promise<string | undefined> | void;
  getAccessToken: () => Promise<string | undefined> | void;
  track: (eventName: string, properties?: EventProperties) => void;
  showFeatureGate: (featureGateModal: FeatureGateModal) => void;
  acceptAccountInvitation: (
    invitationCode: string
  ) => Promise<string | undefined> | void;
  skipOnboarding: () => Promise<string | undefined> | void;
  skipOnboardingCondition: (
    condition: OnboardingCondition
  ) => Promise<string | undefined> | void;
}>({
  authUser: null,
  dbUser: null,
  setDbUser: () => {},
  account: null,
  planUsage: null,
  setPlanUsage: () => {},
  retrievePlanUsage: () => {},
  onboarding: null,
  setOnboarding: () => {},
  retrieveOnboarding: () => {},
  currentPlan: null,
  nextPlan: null,
  signIn: () => {},
  completeNewPassword: () => {},
  loginWithAmazon: () => {},
  signUp: () => {},
  resendSignUp: () => {},
  confirmSignUpAndSignIn: () => {},
  logout: () => {},
  forgotPassword: () => {},
  forgotPasswordSubmitAndSignIn: () => {},
  loginWithAmazonError: null,
  updateAttribute: () => {},
  support: false,
  updateUserAndAccount: () => {},
  accountError: null,
  invitationCode: null,
  makeUserAccountOwner: () => {},
  removeUserFromAccount: () => {},
  configured: false,
  disableLoginWithAmazon: () => {},
  setCurrentUser: () => {},
  changePassword: () => {},
  updateEmail: () => {},
  uploadAccountLogo: () => {},
  removeAccountLogo: () => {},
  getAccessToken: () => {},
  track: () => {},
  showFeatureGate: () => {},
  acceptAccountInvitation: () => {},
  skipOnboarding: () => {},
  skipOnboardingCondition: () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [cookies, setCookie, removeCookie] = useCookies([
    "accountId",
    "invitationCode",
    "returnTo",
  ]);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>();
  const [tempCognitoUser, setTempCognitoUser] = useState<CognitoUser | null>();
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);
  const [dbUser, setDbUser] = useState<DBUser | null>(null);
  const [account, setAccount] = useState<Account | null>(null);
  const [planUsage, setPlanUsage] = useState<PlanUsage | null>(null);
  const [onboarding, setOnboarding] = useState<Onboarding | null>(null);
  const [currentPlan, setCurrentPlan] = useState<PlanDefinitionMapValue | null>(
    null
  );
  const [nextPlan, setNextPlan] = useState<PlanDefinitionMapValue | null>(null);
  const [featureGateModalOpen, setFeatureGateModalOpen] = useState(false);
  const [featureGateModal, setFeatureGateModal] =
    useState<FeatureGateModal | null>(null);
  const [loginWithAmazonError, setLoginWithAmazonError] = useState<
    string | null
  >(null);
  const [accountError, setAccountError] = useState<string | null>(null);
  const [configured, setConfigured] = useState(false);
  const { analytics: analyticsBrowser } = useAnalytics();

  useEffect(() => {
    if (!amplifyConfigured) {
      Amplify.configure(amplifyConfig);
      amplifyConfigured = true;
      setConfigured(true);
    }
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          const returnTo = searchParams?.get("return_to")
            ? Array.isArray(searchParams?.get("return_to"))
              ? searchParams.get("return_to")![0] ?? "/launcher"
              : searchParams.get("return_to")!
            : cookies.returnTo ?? "/launcher";
          removeCookie("returnTo");
          router.push(returnTo);
          break;
        case "signOut":
          router.push("/signin");
          break;
        case "tokenRefresh_failure":
          router.push("/signin");
          break;
        case "tokenRefresh":
          console.log("Access Token Refreshed");
          break;
        case "cognitoHostedUI_failure":
          let message = "Error using Login with Amazon.";
          if (data instanceof Error) {
            message = decodeURIComponent(data.message.replace(/\+/g, "%20"));
            if (message.indexOf(";") > 0) {
              message = message.substring(0, message.indexOf(";"));
            }
            message = message.split("PreSignUp failed with error ")[1];
          }
          setLoginWithAmazonError(message);
          break;
      }
    });

    return unsubscribe;
  }, [setConfigured, router, cookies, removeCookie, searchParams]);

  const setCurrentUser = useCallback(async (bypassCache?: boolean) => {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: bypassCache as boolean,
      });
      setCognitoUser(user);
      setAuthUser(new AuthUser(user));
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setCurrentUser();
  }, [setCurrentUser, pathname]);

  const getAccessToken = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      return session.getAccessToken().getJwtToken();
    } catch (e) {
      console.error(e);
    }
  }, []);

  const retrievePlanUsage = useCallback(async () => {
    if (authUser?.id && account?.id) {
      try {
        const response = await callBackend({
          uri: "/billing/usage",
          method: "GET",
          userId: authUser.id,
          accountId: cookies.accountId,
          accessToken: await getAccessToken(),
        });
        let usage: PlanUsage;
        if (response.ok) {
          const body = await response.json();
          usage = new PlanUsage(body);
          usage.accountId = account.id;
          setPlanUsage(usage);

          if (account.stripePriceId != null) {
            const currentPlan = planDefinitionMap.get(account.stripePriceId);
            if (currentPlan != null) {
              setCurrentPlan(currentPlan);
              setNextPlan(
                planDefinitionMap.get(currentPlan.next_plan ?? "") ?? null
              );
            }
          }
        }
      } catch (error) {
        console.log(error);
        if (error instanceof Error) {
          setAccountError(`Error retrieving usage: ${error.message}`);
        }
        // TODO: Fallback to some sort of Sentry error
      }
    }
  }, [
    setPlanUsage,
    getAccessToken,
    authUser?.id,
    account?.id,
    account?.stripePriceId,
    cookies,
  ]);

  useEffect(() => {
    if (!planUsage) {
      retrievePlanUsage();
    }
  }, [retrievePlanUsage, planUsage]);

  const retrieveOnboarding = useCallback(async () => {
    if (authUser?.id && account?.id) {
      try {
        const response = await callBackend({
          uri: "/onboarding",
          method: "GET",
          userId: authUser.id,
          accountId: cookies.accountId,
          accessToken: await getAccessToken(),
        });
        let onboarding: Onboarding;
        if (response.ok) {
          const body = await response.json();
          onboarding = new Onboarding(body);
          setOnboarding(onboarding);
        }
      } catch (error) {
        console.log(error);
        if (error instanceof Error) {
          setAccountError(`Error retrieving onboarding: ${error.message}`);
        }
        // TODO: Fallback to some sort of Sentry error
      }
    }
  }, [setOnboarding, getAccessToken, authUser?.id, account?.id, cookies]);

  useEffect(() => {
    if (!onboarding) {
      retrieveOnboarding();
    }
  }, [retrieveOnboarding, onboarding]);

  useInterval(
    retrieveOnboarding,
    onboarding?.inProgress() != null ? 5000 : null
  );

  const getOrCreateUserAndAccountFn = useCallback(
    async (force: boolean) => {
      setAccountError(null);
      if (
        authUser &&
        (force || !dbUser || (account && !document.cookie.includes(account.id)))
      ) {
        try {
          // Note: react-cookie's useCookie doesn't update its state from Set-Cookie server responses (which we use).
          // Check the document's cookies (source of truth) until fixed: https://github.com/reactivestack/cookies/issues/281
          const savedAccountId = document.cookie.includes("accountId")
            ? cookies.accountId ?? ""
            : "";
          // Get User and Account
          const response = await callBackend({
            uri: "/accounts",
            userId: authUser.id,
            accountId: savedAccountId,
            accessToken: await getAccessToken(),
          });
          if (response.ok) {
            const data = await response.json();
            setDbUser(new DBUser(data?.user));
            setAccount(new Account(data?.account));
            setCookie("accountId", data?.account?.id, { path: "/" });
          } else {
            if (response.status === 404) {
              const errorText = await response.text();
              if (errorText.includes("user")) {
                // If User not found, create Account
                const data: any = {
                  user_id: authUser.id,
                  user_email: authUser.email.toLowerCase(),
                  user_first_name: authUser.firstName,
                  user_last_name: authUser.lastName,
                  user_name: authUser.name,
                };
                if (cookies.invitationCode) {
                  data.invitation_code = cookies.invitationCode;
                }
                const response = await callBackend({
                  uri: "/accounts",
                  method: "POST",
                  userId: authUser.id,
                  accessToken: await getAccessToken(),
                  data,
                });
                if (response.ok) {
                  const data = await response.json();
                  if (data == null) {
                    setAccountError(`Error creating account: no data returned`);
                    return;
                  }

                  const dbUser = new DBUser(data.user);
                  const account = new Account(data.account);
                  setDbUser(dbUser);
                  setAccount(account);
                  setCookie("accountId", account.id, { path: "/" });
                  removeCookie("invitationCode", { path: "/" });

                  // Alias anonymous ID to newly created user ID
                  try {
                    await analyticsBrowser.alias(dbUser.id);
                  } catch (err) {
                    console.error(`Error aliasing to ${dbUser.id}`);
                  }

                  // Identify new account
                  const traits: AnalyticsTraits = {
                    account_id: dbUser.accountId,
                    created_at: dbUser.createdAt,
                    email: dbUser.email.toLowerCase(),
                    first_name: dbUser.firstName,
                    last_name: dbUser.lastName,
                    name: `${dbUser.firstName}${
                      dbUser.firstName && dbUser.lastName ? " " : ""
                    }${dbUser.lastName}`,
                    is_admin: dbUser.isAdmin,
                    app_version_major: 1,
                    app_version_minor: 0,
                    app_version_patch: 0,
                  };
                  // For Intercom [Deprecated]
                  // Set account if user is working in context of one
                  if (account && !dbUser.isAdmin) {
                    traits.company = {
                      id: dbUser.accountId,
                      name: account.name ? account.name : dbUser.getName(),
                      created_at: account.createdAt,
                      plan: account.stripePriceId,
                      stripe_subscription_status:
                        account.stripeSubscriptionStatus,
                      stripe_subscription_period_end:
                        account.stripeSubscriptionPeriodEnd,
                      stripe_customer_id: account.stripeCustomerId,
                      stripe_subscription_id: account.stripeSubscriptionId,
                      active_listings: 0,
                      hyperdrive_listings: 0,
                      ai_listings: 0,
                      repricing_listings: 0,
                      strategies: 0,
                      ai_strategies: 0,
                      marketplaces: 0,
                      users: 1,
                      workflows: 0,
                      il_integration_enabled: false,
                      scanpower_integration_enabled: false,
                      restockpro_integration_enabled: false,
                      last_30_days_sales: 0,
                    };
                  }
                  await analyticsBrowser.identify(dbUser.id, traits, {
                    Intercom: {
                      user_hash: dbUser.intercomUserHash,
                    },
                  });
                  const groupTraits = {
                    name: account.name ? account.name : dbUser.getName(),
                    plan: account.stripePriceId,
                    stripe_subscription_status:
                      account.stripeSubscriptionStatus,
                    stripe_subscription_period_end:
                      account.stripeSubscriptionPeriodEnd,
                    stripe_customer_id: account.stripeCustomerId,
                    stripe_subscription_id: account.stripeSubscriptionId,
                    // Usage
                    active_listings: 0,
                    hyperdrive_listings: 0,
                    ai_listings: 0,
                    repricing_listings: 0,
                    strategies: 0,
                    ai_strategies: 0,
                    marketplaces: 0,
                    users: 1,
                    workflows: 0,
                    il_integration_enabled: false,
                    scanpower_integration_enabled: false,
                    restockpro_integration_enabled: false,
                    last_30_days_sales: 0,
                  };
                  await analyticsBrowser.group(account.id, groupTraits);
                  analyticsBrowser.ready(() => {
                    if (
                      typeof window !== "undefined" &&
                      (window as any).mixpanel != null
                    ) {
                      (window as any).mixpanel
                        .get_group("account_id", account.id)
                        ?.set(groupTraits);
                    }
                  });

                  // Send event to track trial start event
                  await analyticsBrowser.track("StartTrial", {
                    "Google Adwords New": {
                      order_id: account.id,
                      email: authUser.email.toLowerCase(),
                    },
                    account_id: account.id,
                  });

                  // Track signup event in PartnerStack
                  if (
                    typeof window !== "undefined" &&
                    (window as any).growsumo != null &&
                    (window as any).growsumo.data != null &&
                    account.stripeSubscriptionId != null
                  ) {
                    // Populate PS data values
                    (window as any).growsumo.data.name = account.name
                      ? account.name
                      : dbUser.getName();
                    (window as any).growsumo.data.email = dbUser.email;
                    (window as any).growsumo.data.customer_key =
                      account.stripeSubscriptionId;

                    (window as any).growsumo.createSignup((data: any) => {
                      console.log("Partner conversion recorded", data);
                    });
                  }

                  // Send 'trial' event to Tapfiliate
                  if (
                    typeof window !== "undefined" &&
                    (window as any).tap != null &&
                    account.stripeCustomerId != null
                  ) {
                    (window as any).tap(
                      "trial",
                      account.stripeCustomerId,
                      {
                        program_group: "goaura",
                        always_callback: true,
                      },
                      (err: any, data: any) => {
                        console.log("Partner conversion recorded", err, data);
                      }
                    );
                  }
                } else {
                  const errorText = await response.text();
                  console.log(response.status, errorText);
                  setAccountError(`Error creating account: ${errorText}`);
                  // TODO: Fallback to some sort of Sentry error
                }
              } else if (errorText.includes("account")) {
                // If Account not found, remove cookie and try again
                removeCookie("accountId");
              } else {
                const errorText = await response.text();
                console.log(response.status, errorText);
                setAccountError(`Error retrieving account: ${errorText}`);
                // TODO: Fallback to some sort of Sentry error
              }
            } else {
              const errorText = await response.text();
              console.log(response.status, errorText);
              setAccountError(`Error retrieving account: ${errorText}`);
              // TODO: Fallback to some sort of Sentry error
            }
          }
        } catch (error) {
          console.log(error);
          if (error instanceof Error) {
            setAccountError(
              `Error creating or retrieving account: ${error.message}`
            );
          }
          // TODO: Fallback to some sort of Sentry error
        }
      } else if (!authUser) {
        setDbUser(null);
      }
    },
    [
      authUser,
      dbUser,
      account,
      cookies,
      setCookie,
      removeCookie,
      setDbUser,
      setAccount,
      setAccountError,
      getAccessToken,
      analyticsBrowser,
    ]
  );

  const getOrCreateUserAndAccount = useMutation({
    mutationFn: (force: boolean) => {
      return getOrCreateUserAndAccountFn(force);
    },
    scope: {
      id: "getOrCreateUserAndAccount",
    },
  });

  useEffect(() => {
    getOrCreateUserAndAccount.mutate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrCreateUserAndAccountFn, pathname]);

  const signUp = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string
    ) => {
      await Auth.signUp({
        username: email.toLowerCase(),
        password,
        attributes: {
          email: email.toLowerCase(),
          given_name: firstName,
          family_name: lastName,
        },
      });
    },
    []
  );

  const resendSignUp = useCallback(async (email: string) => {
    await Auth.resendSignUp(email.toLowerCase());
  }, []);

  const invitationCode = useMemo(() => {
    if (searchParams?.get("invitation_code"))
      return searchParams?.get("invitation_code");
    return null;
  }, [searchParams]);

  const returnTo = useMemo(() => {
    if (searchParams?.get("return_to"))
      return searchParams.get("return_to") as string;
    return null;
  }, [searchParams]);

  const saveInvitationCode = useCallback(async () => {
    if (invitationCode && invitationCode.length > 0) {
      setCookie("invitationCode", invitationCode, { path: "/" });
    } else {
      removeCookie("invitationCode", { path: "/" });
    }
  }, [invitationCode, setCookie, removeCookie]);

  const saveReturnTo = useCallback(async () => {
    if (returnTo && returnTo.length > 0) {
      setCookie("returnTo", returnTo, { path: "/" });
    } else {
      removeCookie("returnTo", { path: "/" });
    }
  }, [returnTo, setCookie, removeCookie]);

  const confirmSignUpAndSignIn = useCallback(
    async (email: string, password: string, code: string) => {
      await Auth.confirmSignUp(email.toLowerCase(), code);
      await saveInvitationCode();
      await Auth.signIn(email.toLowerCase(), password);
    },
    [saveInvitationCode]
  );

  const signIn = useCallback(
    async (email: string, password: string, fromLwa: boolean) => {
      setLoginWithAmazonError(null);
      const user = await Auth.signIn(email.toLowerCase(), password);
      if (fromLwa) {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          await Auth.completeNewPassword(user, NewRandomPassword());
        }
      } else {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setTempCognitoUser(user);
          return true;
        }
      }
      return false;
    },
    []
  );

  const completeNewPassword = useCallback(
    async (password: string) => {
      1;
      await Auth.completeNewPassword(tempCognitoUser, password);
      setTempCognitoUser(null);
    },
    [tempCognitoUser]
  );

  const loginWithAmazon = useCallback(
    async (signin?: boolean) => {
      if (signin) {
        await saveInvitationCode();
        await saveReturnTo();
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Amazon,
        });
      } else {
        await StartOAuthFlow();
      }
      setLoginWithAmazonError(null);
    },
    [saveInvitationCode, saveReturnTo, setLoginWithAmazonError]
  );

  const logout = useCallback(async () => {
    setCognitoUser(null);
    setAuthUser(null);
    setAccount(null);
    setDbUser(null);
    setPlanUsage(null);
    setOnboarding(null);
    removeCookie("accountId", { path: "/" });
    removeCookie("invitationCode", { path: "/" });
    removeCookie("returnTo", { path: "/" });
    await Auth.signOut({ global: true });
  }, [removeCookie]);

  const forgotPassword = useCallback(async (email: string) => {
    await Auth.forgotPassword(email.toLowerCase());
  }, []);

  const forgotPasswordSubmitAndSignIn = useCallback(
    async (email: string, code: string, newPassword: string) => {
      await Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword);
      await Auth.signIn(email.toLowerCase(), newPassword);
    },
    []
  );

  const updateAttribute = useCallback(
    async (name: string, value: string) => {
      const attributes: { [key: string]: string } = {};
      attributes[name] = value;
      await Auth.updateUserAttributes(cognitoUser, attributes);
      await setCurrentUser();
    },
    [cognitoUser, setCurrentUser]
  );

  const support = useMemo(() => {
    if (dbUser && account) {
      return dbUser.isAdmin && dbUser.accountId !== account.id;
    }
  }, [dbUser, account]);

  const updateUserAndAccount = useCallback(
    async (data: {
      account_name?: string;
      account_default_currency_code?: string;
      account_logo_url?: string;
      user_first_name?: string;
      user_last_name?: string;
      user_settings?: Settings;
    }) => {
      if (authUser) {
        const response = await callBackend({
          uri: "/accounts",
          method: "PATCH",
          userId: authUser.id,
          accountId: cookies.accountId,
          accessToken: await getAccessToken(),
          data,
        });
        if (response.ok) {
          await getOrCreateUserAndAccount.mutateAsync(true);
        } else {
          const errorText = await response.text();
          return errorText;
        }
      }
    },
    [authUser, cookies, getOrCreateUserAndAccount, getAccessToken]
  );

  const makeUserAccountOwner = useCallback(
    async (id: string) => {
      const response = await callBackend({
        uri: "/accounts/owner",
        method: "PUT",
        userId: authUser?.id,
        accountId: account?.id,
        accessToken: await getAccessToken(),
        data: {
          user_id: id,
        },
      });
      if (response.ok) {
        await getOrCreateUserAndAccount.mutateAsync(true);
      } else {
        const errorText = await response.text();
        return errorText;
      }
    },
    [authUser, account, getOrCreateUserAndAccount, getAccessToken]
  );

  const removeUserFromAccount = useCallback(
    async (id: string) => {
      const response = await callBackend({
        uri: "/accounts/user",
        method: "DELETE",
        userId: authUser?.id,
        accountId: account?.id,
        accessToken: await getAccessToken(),
        data: {
          user_id: id,
        },
      });
      if (response.ok) {
        if (authUser?.id === id) {
          await logout();
        }
        await getOrCreateUserAndAccount.mutateAsync(true);
      } else {
        const errorText = await response.text();
        return errorText;
      }
    },
    [authUser, account, getOrCreateUserAndAccount, logout, getAccessToken]
  );

  const disableLoginWithAmazon = useCallback(async () => {
    const response = await fetch("/api/disableLwa");
    if (response.ok) {
      setCurrentUser(true);
    } else {
      const errorText = await response.text();
      return errorText;
    }
  }, [setCurrentUser]);

  const changePassword = useCallback(
    async (oldPassword: string, newPassword: string) => {
      try {
        await Auth.changePassword(cognitoUser, oldPassword, newPassword);
      } catch (error) {
        if (error instanceof Error) {
          return error.message;
        }
      }
    },
    [cognitoUser]
  );

  const updateEmail = useCallback(
    async (newEmail: string) => {
      try {
        const response = await fetch("/api/updateEmail", {
          method: "POST",
          body: JSON.stringify({
            email: newEmail.toLowerCase(),
          }),
        });
        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage);
        }
        await setCurrentUser(true);
        await getOrCreateUserAndAccount.mutateAsync(true);
      } catch (error) {
        if (error instanceof Error) {
          return error.message;
        }
      }
    },
    [setCurrentUser, getOrCreateUserAndAccount]
  );

  const uploadAccountLogo = useCallback(
    async (file: File) => {
      const response = await callBackend({
        uri: "/accounts/logo",
        method: "POST",
        userId: authUser?.id,
        accountId: account?.id,
        accessToken: await getAccessToken(),
        data: file,
        rawData: true,
      });
      if (response.ok) {
        await getOrCreateUserAndAccount.mutateAsync(true);
      } else {
        const errorText = await response.text();
        return errorText;
      }
    },
    [authUser, account, getOrCreateUserAndAccount, getAccessToken]
  );

  const removeAccountLogo = useCallback(async () => {
    const response = await callBackend({
      uri: "/accounts/logo",
      method: "DELETE",
      userId: authUser?.id,
      accountId: account?.id,
      accessToken: await getAccessToken(),
    });
    if (response.ok) {
      await getOrCreateUserAndAccount.mutateAsync(true);
    } else {
      const errorText = await response.text();
      return errorText;
    }
  }, [authUser, account, getOrCreateUserAndAccount, getAccessToken]);

  useEffect(() => {
    async function identify() {
      if (dbUser && planUsage && !dbUser.isAdmin) {
        const traits: AnalyticsTraits = {
          account_id: dbUser.accountId,
          created_at: dbUser.createdAt,
          email: dbUser.email.toLowerCase(),
          first_name: dbUser.firstName,
          last_name: dbUser.lastName,
          name: `${dbUser.firstName}${
            dbUser.firstName && dbUser.lastName ? " " : ""
          }${dbUser.lastName}`,
          is_admin: dbUser.isAdmin,
          app_version_major: 1,
          app_version_minor: 0,
          app_version_patch: 0,
        };

        // For Intercom [Deprecated]
        // Set account if user is working in context of one
        if (
          account &&
          !dbUser.isAdmin &&
          (!planUsage.accountId || planUsage.accountId === account.id)
        ) {
          traits.company = {
            id: dbUser.accountId,
            name: account.name ? account.name : dbUser.getName(),
            created_at: account.createdAt,
            plan: account.stripePriceId,
            stripe_subscription_status: account.stripeSubscriptionStatus,
            stripe_subscription_period_end: account.stripeSubscriptionPeriodEnd,
            stripe_customer_id: account.stripeCustomerId,
            stripe_subscription_id: account.stripeSubscriptionId,
            active_listings: planUsage.activeListings,
            hyperdrive_listings: planUsage.hyperdriveListings,
            ai_listings: planUsage.aiListings,
            repricing_listings: planUsage.repricingListings,
            strategies: planUsage.strategies,
            ai_strategies: planUsage.aiStrategies,
            marketplaces: planUsage.marketplaces,
            users: planUsage.users,
            workflows: planUsage.workflows,
            il_integration_enabled: planUsage.ilIntegrationEnabled,
            scanpower_integration_enabled:
              planUsage.scanPowerIntegrationEnabled,
            restockpro_integration_enabled:
              planUsage.restockProIntegrationEnabled,
            last_30_days_sales: planUsage.last30DaysSales / 100,
          };
        }
        await analyticsBrowser.identify(dbUser.id, traits, {
          Intercom: {
            user_hash: dbUser.intercomUserHash,
          },
        });

        if (account) {
          // Canny Identify
          const userData: CannyUserData = {
            created: dbUser.createdAt.toISOString(),
            email: dbUser.email.toLowerCase(),
            id: dbUser.id,
            name: dbUser.getName(),
          };
          if (!dbUser.isAdmin) {
            userData.companies = [
              {
                created: account.createdAt.toISOString(),
                id: account.id,
                name: account.name ? account.name : dbUser.getName(),
              },
            ];
          }
          if (typeof window !== "undefined" && (window as any).Canny != null) {
            (window as any).Canny("identify", {
              appID: "61e1de980b84dc0ff13a2fdc",
              user: userData,
            });
            (window as any).Canny("initChangelog", {
              appID: "61e1de980b84dc0ff13a2fdc",
              position: "bottom",
              align: "right",
              theme: "light",
            });
          }

          const groupTraits = {
            name: account.name ? account.name : dbUser.getName(),
            plan: account.stripePriceId,
            stripe_subscription_status: account.stripeSubscriptionStatus,
            stripe_subscription_period_end: account.stripeSubscriptionPeriodEnd,
            stripe_customer_id: account.stripeCustomerId,
            stripe_subscription_id: account.stripeSubscriptionId,
            // Usage
            active_listings: planUsage.activeListings,
            hyperdrive_listings: planUsage.hyperdriveListings,
            ai_listings: planUsage.aiListings,
            repricing_listings: planUsage.repricingListings,
            strategies: planUsage.strategies,
            ai_strategies: planUsage.aiStrategies,
            marketplaces: planUsage.marketplaces,
            users: planUsage.users,
            workflows: planUsage.workflows,
            il_integration_enabled: planUsage.ilIntegrationEnabled,
            scanpower_integration_enabled:
              planUsage.scanPowerIntegrationEnabled,
            restockpro_integration_enabled:
              planUsage.restockProIntegrationEnabled,
            last_30_days_sales: planUsage.last30DaysSales / 100,
          };
          await analyticsBrowser.group(account.id, groupTraits);
          analyticsBrowser.ready(() => {
            if (
              typeof window !== "undefined" &&
              (window as any).mixpanel != null
            ) {
              (window as any).mixpanel
                .get_group("account_id", account.id)
                ?.set(groupTraits);
            }
          });
        }
      }
    }
    identify();
  }, [dbUser, analyticsBrowser, account, planUsage]);

  // Track page views
  useEffect(() => {
    analyticsBrowser.ready(() => {
      let utmParams: EventProperties | undefined = undefined;
      if (typeof window !== "undefined" && (window as any).mixpanel != null) {
        // TODO: Use super properties pattern from track call below
        // Emit page view event to MixPanel,
        // then extract UTM parameter super properties attached to event
        // to attach to Segment page event
        let pageView: SegmentEvent = (window as any).mixpanel.track_pageview();
        if (pageView.properties != null) {
          utmParams = (({
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_term,
          }) => ({
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_term,
          }))(pageView.properties);
        }
      }
      analyticsBrowser.page(undefined, undefined, utmParams);
    });
  }, [searchParams, analyticsBrowser]);

  const track = useCallback(
    (eventName: string, properties?: EventProperties) => {
      analyticsBrowser.ready(() => {
        let superProperties: EventProperties = {};
        if (typeof window !== "undefined" && (window as any).mixpanel != null) {
          let props: EventProperties =
            (window as any).mixpanel.persistence?.props ?? {};
          superProperties = (({
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_term,
          }) => ({
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_term,
          }))(props);
        }
        analyticsBrowser.track(
          eventName,
          account
            ? { ...properties, ...superProperties, account_id: account.id }
            : { ...properties, ...superProperties }
        );
      });
    },
    [analyticsBrowser, account]
  );

  const showFeatureGate = useCallback(
    (featureGateModal: FeatureGateModal) => {
      setFeatureGateModal(featureGateModal);
      setFeatureGateModalOpen(true);
    },
    [setFeatureGateModal, setFeatureGateModalOpen]
  );

  const acceptAccountInvitation = useCallback(
    async (id: string) => {
      const response = await callBackend({
        uri: "/accounts/invitation",
        method: "POST",
        userId: authUser?.id,
        accountId: account?.id,
        accessToken: await getAccessToken(),
        data: {
          invitation_code: id,
        },
      });
      if (response.ok) {
        await router.replace("/admin/out");
        await router.refresh();
      } else {
        const errorText = await response.text();
        return errorText;
      }
    },
    [authUser, account, router, getAccessToken]
  );

  const skipOnboarding = useCallback(async () => {
    const response = await callBackend({
      uri: "/onboarding/skip",
      method: "POST",
      userId: authUser?.id,
      accountId: account?.id,
      accessToken: await getAccessToken(),
    });
    if (response.ok) {
      await retrieveOnboarding();
    } else {
      const errorText = await response.text();
      return errorText;
    }
  }, [authUser, account, retrieveOnboarding, getAccessToken]);

  const skipOnboardingCondition = useCallback(
    async (condition: OnboardingCondition) => {
      const response = await callBackend({
        uri: `/onboarding/skip/${condition}`,
        method: "POST",
        userId: authUser?.id,
        accountId: account?.id,
        accessToken: await getAccessToken(),
      });
      if (response.ok) {
        await retrieveOnboarding();
      } else {
        const errorText = await response.text();
        return errorText;
      }
    },
    [authUser, account, retrieveOnboarding, getAccessToken]
  );

  return (
    <AuthContext.Provider
      value={{
        authUser,
        dbUser,
        setDbUser,
        account,
        planUsage,
        setPlanUsage,
        retrievePlanUsage,
        onboarding,
        setOnboarding,
        retrieveOnboarding,
        currentPlan,
        nextPlan,
        signUp,
        resendSignUp,
        confirmSignUpAndSignIn,
        signIn,
        completeNewPassword,
        loginWithAmazon,
        logout,
        forgotPassword,
        forgotPasswordSubmitAndSignIn,
        loginWithAmazonError,
        updateAttribute,
        support,
        updateUserAndAccount,
        accountError,
        invitationCode,
        makeUserAccountOwner,
        removeUserFromAccount,
        configured,
        disableLoginWithAmazon,
        setCurrentUser,
        changePassword,
        updateEmail,
        uploadAccountLogo,
        removeAccountLogo,
        getAccessToken,
        track,
        showFeatureGate,
        acceptAccountInvitation,
        skipOnboarding,
        skipOnboardingCondition,
      }}
    >
      {children}
      <ConfirmationModal
        open={featureGateModalOpen}
        setOpen={setFeatureGateModalOpen}
        title={featureGateModal?.title ?? "You've hit your plan limit"}
        href={featureGateModal?.href}
        saveText={featureGateModal?.href ? "Upgrade" : "Close"}
        variant="primary"
        newTab={false}
      >
        {featureGateModal?.body}
      </ConfirmationModal>
    </AuthContext.Provider>
  );
}
