export class Onboarding {
  state: OnboardingState | null;
  hasMarketplace: OnboardingState | null;
  hasStrategy: OnboardingState | null;
  hasListingsWithStrategy: OnboardingState | null;
  hasListingsWithRepricing: OnboardingState | null;

  constructor(onboarding: any) {
    if (!onboarding) {
      throw new Error("error constructing onboarding");
    }
    this.state = onboarding.state ?? null;
    this.hasMarketplace = onboarding.has_marketplace ?? null;
    this.hasStrategy = onboarding.has_strategy ?? null;
    this.hasListingsWithStrategy =
      onboarding.has_listings_with_strategy ?? null;
    this.hasListingsWithRepricing =
      onboarding.has_listings_with_repricing ?? null;
  }

  conditionsCompleted(): number {
    return (
      (onboardingStateCompleted(this.hasMarketplace) ? 1 : 0) +
      (onboardingStateCompleted(this.hasStrategy) ? 1 : 0) +
      (onboardingStateCompleted(this.hasListingsWithStrategy) ? 1 : 0) +
      (onboardingStateCompleted(this.hasListingsWithRepricing) ? 1 : 0)
    );
  }

  completed(): boolean {
    return this.state === "completed";
  }

  skipped(): boolean {
    return this.state === "skipped";
  }

  inProgress(): boolean {
    return this.state == null;
  }

  nextCondition(): OnboardingCondition | null {
    if (!this.hasMarketplace) return "has_marketplace";
    if (!this.hasStrategy) return "has_strategy";
    if (!this.hasListingsWithStrategy) return "has_listings_with_strategy";
    if (!this.hasListingsWithRepricing) return "has_listings_with_repricing";
    return null;
  }
}

export type OnboardingState = "completed" | "skipped" | "pending";

export function onboardingStateCompleted(
  state: OnboardingState | null
): boolean {
  return !!state && state !== "pending";
}

export type OnboardingCondition =
  | "has_marketplace"
  | "has_strategy"
  | "has_listings_with_strategy"
  | "has_listings_with_repricing";

export function nextCondition(
  condition: OnboardingCondition
): OnboardingCondition | null {
  switch (condition) {
    case "has_marketplace":
      return "has_strategy";
    case "has_strategy":
      return "has_listings_with_strategy";
    case "has_listings_with_strategy":
      return "has_listings_with_repricing";
    case "has_listings_with_repricing":
      return null;
  }
}
